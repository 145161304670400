.banner {
    &.banner--slider,
    &.banner--video {
        position: relative;
        overflow: hidden;

        @if $header--sticky {
            padding-top: $header__height--mobile;

            @include media-breakpoint-up(lg) {
                padding-top: $header__height--unpinned;
            }

            @include media-breakpoint-up(xxl) {
                padding-top: $header__height;
            }
        }

        &.banner--fullscreen {
            @if $header--sticky {
                max-height: 100vh;
                max-height: calc(var(--vh, 1vh) * 100);
            } @else {
                max-height: calc(100vh - #{$header__height--mobile});
                max-height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile});
            }

            @include media-breakpoint-up(lg) {
                @if not $header--sticky {
                    max-height: calc(100vh - #{$header__height--unpinned});
                    max-height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned});
                }
            }

            @include media-breakpoint-up(xxl) {
                @if not $header--sticky {
                    max-height: calc(100vh - #{$header__height});
                    max-height: calc((var(--vh, 1vh) * 100) - #{$header__height});
                }
            }
        }
    }

    &.banner--slider {
        &.banner--fullscreen {
            &[data-items='1'] {
                figure {
                    @include media-breakpoint-up(lg) {
                        height: calc(100vh - #{$header__height--unpinned});
                        height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned});
                    }

                    @include media-breakpoint-up(xxl) {
                        height: calc(100vh - #{$header__height});
                        height: calc((var(--vh, 1vh) * 100) - #{$header__height});
                    }
                }
            }

            figure {
                position: relative;
                //height: calc(100vh - #{$header__height--mobile} - 64px - 106px);
                //height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 64px - 106px); // less navigation panel & direct links teaser
                height: calc(100vh - #{$header__height--mobile} - 106px);
                height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 106px); // less direct links teaser
                overflow: hidden;

                @include media-breakpoint-up(lg) {
                    height: calc(100vh - #{$header__height--unpinned} - 80px);
                    height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned} - 80px);
                }

                @include media-breakpoint-up(xxl) {
                    height: calc(100vh - #{$header__height} - 80px);
                    height: calc((var(--vh, 1vh) * 100) - #{$header__height} - 80px);
                }

                picture {
                    position: absolute;
                    top: 0; left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        &:not(.banner--fullscreen) {
            figure {
                picture {
                    overflow: hidden;

                    @include media-breakpoint-between(lg, xxl) {
                        display: flex;
                        height: calc(525 / 1920 * map-get($grid-breakpoints, xxl));
                    }

                    @media (min-width: 1920px) {
                        height: 525px;
                    }

                    img {
                        @include media-breakpoint-between(lg, xxl) {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        @media (min-width: 1920px) {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        figure {
            picture {
                img {
                    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1);
                }
            }
        }

        .splide {
            &__slide {
                &.is-active {
                    figure {
                        picture {
                            img {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }

            &__footer {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 0;
                height: 64px;

                @include media-breakpoint-down(md) {
                    position: absolute;
                    z-index: 10;
                    right: 0; bottom: 24px; left: 0;
                    height: auto;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        right: 0; bottom: -24px; left: 0;
                        height: 120px;
                        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
                        pointer-events: none;
                    }
                }

                @include media-breakpoint-up(lg) {
                    height: 80px;
                }
            }

            &__pagination {
                button {
                    @include media-breakpoint-down(md) {
                        &::before {
                            border-color: $color__white;
                        }
                    }

                    &.is-active {
                        @include media-breakpoint-down(md) {
                            &::before {
                                background-color: $color__white;
                                border-color: $color__white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.banner--video {
        &.banner--fullscreen {
            figure {
                //height: calc(100vh - #{$header__height--mobile} - 64px - 106px);
                //height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 64px - 106px); // less navigation panel & direct links teaser
                height: calc(100vh - #{$header__height--mobile} - 106px);
                height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 106px); // less direct links teaser

                @include media-breakpoint-up(lg) {
                    height: calc(100vh - #{$header__height--unpinned} - 80px);
                    height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned} - 80px);
                }

                @include media-breakpoint-up(xxl) {
                    height: calc(100vh - #{$header__height} - 80px);
                    height: calc((var(--vh, 1vh) * 100) - #{$header__height} - 80px);
                }
            }
        }

        &:not(.banner--fullscreen) {
            figure {
                padding-top: 75%;
                height: 0;

                @include media-breakpoint-up(md) {
                    padding-top: percentage(calc(525 / 1920 * strip_unit(map-get($grid-breakpoints, xxl)) / strip_unit(map-get($grid-breakpoints, lg))));
                }

                @include media-breakpoint-between(lg, xxl) {
                    padding-top: 0;
                    height: calc(525 / 1920 * map-get($grid-breakpoints, xxl));
                }

                @include media-breakpoint-up(xxl) {
                    padding-top: 27.34375%;
                    height: 0;
                }

                @media (min-width: 1920px) {
                    padding-top: 0;
                    height: 525px;
                }
            }
        }

        figure {
            position: relative;
            overflow: hidden;

            video {
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .banner {
            &__footer {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 64px;

                @include media-breakpoint-down(md) {
                    position: absolute;
                    z-index: 10;
                    right: 0; bottom: 24px; left: 0;
                    height: auto;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        right: 0; bottom: -24px; left: 0;
                        height: 120px;
                        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
                        pointer-events: none;
                    }
                }

                @include media-breakpoint-up(lg) {
                    height: 80px;
                }

                button {
                    span {
                        &.icon {
                            &[class*=icon--] {
                                display: unset;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
